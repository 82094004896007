import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Signup from './Signup';

function App() {
  return (
    <div className="App">
    <center>
     <Signup></Signup>
    </center>
    </div>
  );
}

export default App;
